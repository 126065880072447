import React from 'react'
import * as propTypes from 'prop-types'
import Card from '../components/card'
import Grid from '@mui/material/Grid2'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { withStyles } from '@mui/styles'

const styles = {
  font: {
    fontFamily: 'Helvetica',
  },
}

class LandingPage extends React.Component {
  render() {
    // const { headlineCardsData, cardsData, onCardClick, classes } = this.props
    const { cardsData, onCardClick, classes } = this.props
    return (
      <Container
        style={{ marginTop: '20px', marginBottom: '20px', maxWidth: '1250px' }}
      >
        {/* <img src={Pic} style={{ height: '50vh', width: '50vh' }} /> */}
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <Typography
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              color="#CC0000"
              variant="h5"
              className={classes.font}
            >
              {'How to make someone’s day:'}
            </Typography>
            <Typography
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              variant="body1"
              className={classes.font}
            >
              {'1. Make a list of team members to recognize.'}
            </Typography>
            <Typography
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
              variant="body1"
              className={classes.font}
            >
              {'2. Select the card they most embody.'}
            </Typography>
            <Typography
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
              variant="body1"
              className={classes.font}
            >
              {'3. Tell them by sending an e-card.'}
            </Typography>
          </div>
        </Grid>
        {/* Remove this section to get rid of cards at top of page */}

        {/*<div style={{ marginTop: 30 }}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={6}
          >
            {headlineCardsData.map((card) => {
              return (
                <Grid item key={card.name} onClick={(e) => onCardClick(card)}>
                  <Card card={card} />
                </Grid>
              )
            })}
          </Grid>
        </div>*/}

        <div style={{ marginTop: 30 }}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={0}
          >
            {cardsData.map((card) => {
              return (
                <Grid item key={card.name} onClick={() => onCardClick(card)}>
                  <Card card={card} />
                </Grid>
              )
            })}
          </Grid>
        </div>
      </Container>
    )
  }
}
LandingPage.propTypes = {
  cardsData: propTypes.array,
  headlineCardsData: propTypes.array,
  onCardClick: propTypes.func,
}
export default withStyles(styles)(LandingPage)
