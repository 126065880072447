import React from 'react'
import { withStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'

const styles = {
  card: {
    width: 300,
    height: 300,
    borderColor: '#00F',
    transition: 'all 0.8s',
    '&:hover': {
      opacity: '0.3',
      cursor: 'pointer',
    },
  },
  image: {
    maxWidth: '100%',
    maxWeight: '100%',
  },
}

class SimpleCard extends React.Component {
  render() {
    const { classes, card } = this.props
    return (
      <Card className={classes.card}>
        <CardMedia image={card.imgSquare}>
          <img
            className={classes.image}
            alt={card.title}
            src={card.imgSquare}
          />
        </CardMedia>
      </Card>
    )
  }
}

export default withStyles(styles)(SimpleCard)
