import React from 'react'
import Slide from '@mui/material/Slide'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid2'

const styles = (theme) => ({
  title: {
    color: '#cc0000',
  },
  button: {
    margin: theme.spacing(1),
    color: '#ffffff',
    backgroundColor: '#cc0000',
  },
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class Notification extends React.Component {
  handleClose = () => {
    this.props.showNotification(false, '')
  }

  render() {
    const { isShown, classes, message } = this.props
    return (
      <div>
        <Dialog
          open={isShown}
          TransitionComponent={Transition}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <Typography
              classes={{ root: classes.title }}
              variant="h6"
              align="center"
              display="block"
            >
              {'Recognition at Target'}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid container>
                <Typography
                  style={{ paddingTop: 10 }}
                  variant="subtitle2"
                  display="block"
                >
                  {message}
                </Typography>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              classes={{ root: classes.button }}
              onClick={this.handleClose}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(styles)(Notification)
