import React from 'react'
import AppBar from '@mui/material/AppBar'
import { withStyles } from '@mui/styles'
import Signout from '../signout.svg'

class Header extends React.Component {
  render() {
    const { classes, logout, headerClick } = this.props
    return (
      <div className={classes.mainDiv} onClick={(e) => headerClick(e)}>
        <AppBar className={classes.appBar}>
          <div className={classes.YMTGTBar}>
            <h1>Recognition at Target</h1>
            <Signout onClick={logout} className={classes.signout} />
          </div>
        </AppBar>
      </div>
    )
  }
}

const styles = (theme) => ({
  mainDiv: {
    height: 80,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
    [theme.breakpoints.down('md')]: {
      overflowY: 'hidden',
      width: '100%',
    },
    fontFamily: 'Helvetica',
  },
  YMTGTBar: {
    alignItems: 'center',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    color: '#CC0000',
  },
  appBar: {
    height: 56,
    zIndex: theme.zIndex.appBar + 1,
    position: 'fixed',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    height: 35,
    marginLeft: 9,
    width: 35,
  },
  signout: {
    height: 18,
    width: 18,
    position: 'absolute',
    right: '10px',
  },
})

export default withStyles(styles)(Header)
